import React, {Component} from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Home from './components/Home/Home';
import Login from './components/LoginPage/LoginPage'
import 'bootstrap/dist/css/bootstrap.css';
import axios from 'axios';

import Session from "./components/Session/Session";

// Redux;
import { Provider } from 'react-redux';
import store from './redux/store';
import { connectToSocket, setStartUpState } from './redux/actions/stationActions';
import { CONNECTING } from './redux/types';

// BASE URL for axios, auth and middleware will be here soon;
axios.defaults.baseURL = 'https://dmo1.myrianet.net/api';
axios.defaults.proxy = {
    protocol: 'https',
	host: 'dmo1.myrianet.net',
    port: 1338
  };
class App extends Component {
	constructor() {
		super();
        this.store = store;
    }

	componentDidMount() {
		this.store.dispatch(connectToSocket())//.then(ws => setStartUpState(ws))
		this.store.dispatch({
			type: CONNECTING
		})

	}

	render() {
		
		return (
			<Provider store={this.store}>
				<div className="container">
					<Router>
						<Session/>

						<Route path="/main" exact component={Home}/>
						<Route path="/" exact component={Login}/>
					</Router>
				</div>
			</Provider>
		);
	}
}


export default App;

