import { wsConnect } from "../modules/websocket";


// connect to socket;
export const connectToSocket = () => (dispatch) => {
  let host = "wss://ws.dmo1.myrianet.net"
  dispatch(wsConnect(host))


}
