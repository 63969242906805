  import {
    CONNECTING,
    LOADING_USER,
    LOGINFAILED,
    SET_AUTHENTICATED,
    SET_UNAUTHENTICATED,
    SET_LOGOUTST,
    SET_SESSION,
    RM_USR_EXTENSION,
    SET_USER,
    UPDATE_USER,
  } from "../types";
  import axios from "axios";

  
  const initialState = {
    authenticated: false,
    auth: true,
    logoustated: false, 
    credentials: {},
    loading: false,
    connecting: false,
    errors: {}
  };
  
  export default function user(state = initialState, action) {
    switch (action.type) {
     
      case SET_AUTHENTICATED:
        
        let session_id = localStorage.getItem("session_id");
        if(session_id == action.sessiion_id){
            if(action.usreextension){ 
              localStorage.setItem("extension_num",action.usreextension);
            }
            window.sockete['via_transport'] = 'TLS';
            let session_separator_usrname = localStorage.getItem("session_separator_usrname");
            let user_extension = localStorage.getItem("extension_num");
  
            let extension_number = parseInt(user_extension);
          console.log("call extension " + extension_number + " user id " + session_separator_usrname);

            var configuration = {
              sockets  : [ window.sockete ],
              //later change it with 107
              uri: extension_number + (process.env.REACT_APP_FREEPBX_URI),
              password: (process.env.REACT_APP_FREEPBX_PASS),
              registrar_server: (process.env.REACT_APP_FREEPBX_REGISTER_SERVER),
              contact_uri         : null,
              authorization_user  : null,
              instance_id         : null,
              session_timers      : true,
              use_preloaded_route : false
            };
            window.network_state = true;
            var ua = new window.sip.UA(configuration);
            var i=0;
            ua.on('connected', function(e){ console.log('Conn'); });
            ua.on('disconnected', function(e){window.network_state = false; console.log('Disconn internet now'); });
            ua.on('registered', function(e){
               if(window.network_state == false){
               console.log('registered after disconnect'); 
               window.network_state = true;
                setTimeout(() => {
                  if(window.steamchannel_1){
                  if(window.steamchannel_1.active == false && window.state1 == true){
                      window.micval = 1;
                      console.log("this is 1 use to test the streem "+window.steamchannel_1.active);
                      var session = ua.call(window.calldata_1+(process.env.REACT_APP_FREEPBX_URI), options);
                      if (session) {
                        session.connection.addEventListener('addstream', (s) => {
                            var audio = document.createElement('audio');
                            audio.srcObject = s.stream;
                            audio.play();
                            window.audio_v1 = audio;
                            window.steamchannel_1 = s.stream;
                            window.confie1 = true;
                        });
                      }
                      window.state1 = false;
                      window.confie1 = false;
                    }
                  }

                  if(window.steamchannel_2){
                    if (window.steamchannel_2.active == false && window.state2 == true){
                      window.micval = 2;
                      console.log("this is 2 use to test the streem "+window.steamchannel_2.active);
                      var session = ua.call(window.calldata_2+(process.env.REACT_APP_FREEPBX_URI), options);
                      if (session) {
                        session.connection.addEventListener('addstream', (s) => {
                            var audio = document.createElement('audio');
                            audio.srcObject = s.stream;
                            audio.play();
                            window.audio_v2 = audio;
                            window.steamchannel_2 = s.stream;
                            window.confie2 = true;
                        });
                      }
                      window.state2 = false;
                      window.confie2 = false;
                    }
                  }
                  if(window.steamchannel_3){
                    if(window.steamchannel_3.active == false && window.state3 == true){
                      window.micval = 3;
                      console.log("this is 3 use to test the streem "+window.steamchannel_3.active);
                      var session = ua.call(window.calldata_3+(process.env.REACT_APP_FREEPBX_URI), options);
                      if (session) {
                        session.connection.addEventListener('addstream', (s) => {
                            var audio = document.createElement('audio');
                            audio.srcObject = s.stream;
                            audio.play();
                            window.audio_v3 = audio;
                            window.steamchannel_3 = s.stream;
                            window.confie3 = true;
                        });
                      }
                      window.state3 = false;
                      window.confie3 = false;
                    }
                  }
                  if(window.steamchannel_4){
                    if(window.steamchannel_4.active == false && window.state4 == true){
                      window.micval = 4;
                      console.log("this is 4 use to test the streem "+window.steamchannel_4.active);
                      var session = ua.call(window.calldata_4+(process.env.REACT_APP_FREEPBX_URI), options);
                      if (session) {
                        session.connection.addEventListener('addstream', (s) => {
                            var audio = document.createElement('audio');
                            audio.srcObject = s.stream;
                            audio.play();
                            window.audio_v4 = audio;
                            window.steamchannel_4 = s.stream;
                            window.confie4 = true;
                        });
                      }
                      window.state4 = false;
                      window.confie4 = false;
                    }
                  }
                  if(window.steamchannel_5){
                    if(window.steamchannel_5.active == false && window.state5 == true){
                      window.micval = 5;
                      console.log("this is 5 use to test the streem "+window.steamchannel_5.active);
                      var session = ua.call(window.calldata_5+(process.env.REACT_APP_FREEPBX_URI), options);
                      if (session) {
                        session.connection.addEventListener('addstream', (s) => {
                            var audio = document.createElement('audio');
                            audio.srcObject = s.stream;
                            audio.play();
                            window.audio_v5 = audio;
                            window.steamchannel_5 = s.stream;
                            window.confie5 = true;
                        });
                      }
                      window.state5 = false;
                      window.confie5 = false;
                    }
                  }
                  if(window.steamchannel_6){
                    if(window.steamchannel_6.active == false && window.state6 == true){
                      window.micval = 6;
                      console.log("this is 6 use to test the streem "+window.steamchannel_6.active);
                      var session = ua.call(window.calldata_6+(process.env.REACT_APP_FREEPBX_URI), options);
                      if (session) {
                        session.connection.addEventListener('addstream', (s) => {
                            var audio = document.createElement('audio');
                            audio.srcObject = s.stream;
                            audio.play();
                            window.audio_v6 = audio;
                            window.steamchannel_6 = s.stream;
                            window.confie6 = true;
                        });
                      }
                      window.state6 = false;
                      window.confie6 = false;
                    }
                  }
                  if(window.steamchannel_7){
                    if(window.steamchannel_7.active == false && window.state7 == true){
                      window.micval = 7;
                      console.log("this is 7 use to test the streem "+window.steamchannel_7.active);
                      var session = ua.call(window.calldata_7+(process.env.REACT_APP_FREEPBX_URI), options);
                      if (session) {
                        session.connection.addEventListener('addstream', (s) => {
                            var audio = document.createElement('audio');
                            audio.srcObject = s.stream;
                            audio.play();
                            window.audio_v7 = audio;
                            window.steamchannel_7 = s.stream;
                            window.confie7 = true;
                        });
                      }
                      window.state7 = false;
                      window.confie7 = false;
                    }
                  }
                }, 6000);

                } 
               console.log('registered'); 
              });
            ua.on('unregistered', function(e){ console.log('unregistered'); });
            ua.on('registrationFailed', function(e){ console.log('registeredfailed'); });
            var stringArray = new Array();
            ua.on('newRTCSession', function(e){
      
              i++;
              console.log('newRTCSession',i);
              stringArray[window.micval] = e.session
              console.log(window.micval," ",e.session);
              window.mic1=stringArray[1];
              window.mic2=stringArray[2];
              window.mic3=stringArray[3];
              window.mic4=stringArray[4];
              window.mic5=stringArray[5];
              window.mic6=stringArray[6];
              window.mic7=stringArray[7];
              console.log(window.mic1," ",window.mic2," ",window.mic3," ",window.mic4," ",window.mic5," ",window.mic6," ",window.mic7);
            
      
            });
            ua.start();
            console.log('INIT');
            window.confie1 = true;
            window.confie2 = true;
            window.confie3 = true;
            window.confie4 = true;
            window.confie5 = true;
            window.confie6 = true;
            window.confie7 = true;
            
            // window.state = true;
            // Register callbacks to desired call events
            var eventHandlers = {
              'progress': function(e) {
                console.log('call is in progress');
              },
              'failed': function(e) {
                // console.log('call failed with cause: '+ e.data.cause);
              },
              'ended': function(e) {
                console.log('call ended'+e);
                // if(window.state == true){
                  setTimeout(() => {
                    if(window.steamchannel_1){
                    if(window.steamchannel_1.active == false && window.state1 == true && window.confie1 == true){
                        window.micval = 1;
                        console.log("this is 1 use to test the streem "+window.steamchannel_1.active);
                        var session = ua.call(window.calldata_1+(process.env.REACT_APP_FREEPBX_URI), options);
                        if (session) {
                          session.connection.addEventListener('addstream', (s) => {
                              var audio = document.createElement('audio');
                              audio.srcObject = s.stream;
                              audio.play();
                              window.audio_v1 = audio;
                              window.steamchannel_1 = s.stream;
                              window.confie1 = true;
                          });
                        }
                        window.state1 = false;
                        window.confie1 = false;
                      }
                    }

                    if(window.steamchannel_2){
                      if(window.steamchannel_2.active == false && window.state2 == true && window.confie2 == true){
                        window.micval = 2;
                        console.log("this is 2 use to test the streem "+window.steamchannel_2.active);
                        var session = ua.call(window.calldata_2+(process.env.REACT_APP_FREEPBX_URI), options);
                        if (session) {
                          session.connection.addEventListener('addstream', (s) => {
                              var audio = document.createElement('audio');
                              audio.srcObject = s.stream;
                              audio.play();
                              window.audio_v2 = audio;
                              window.steamchannel_2 = s.stream;
                              window.confie2 = true;
                          });
                        }
                        window.state2 = false;
                        window.confie2 = false;
                      }
                    }
                    if(window.steamchannel_3){
                      if(window.steamchannel_3.active == false && window.state3 == true && window.confie3 == true){
                        window.micval = 3;
                        console.log("this is 3 use to test the streem "+window.steamchannel_3.active);
                        var session = ua.call(window.calldata_3+(process.env.REACT_APP_FREEPBX_URI), options);
                        if (session) {
                          session.connection.addEventListener('addstream', (s) => {
                              var audio = document.createElement('audio');
                              audio.srcObject = s.stream;
                              audio.play();
                              window.audio_v3 = audio;
                              window.steamchannel_3 = s.stream;
                              window.confie3 = true;
                          });
                        }
                        window.state3 = false;
                        window.confie3 = false;
                      }
                    }
                    if(window.steamchannel_4){
                      if(window.steamchannel_4.active == false && window.state4 == true && window.confie4 == true){
                        window.micval = 4;
                        console.log("this is 4 use to test the streem "+window.steamchannel_4.active);
                        var session = ua.call(window.calldata_4+(process.env.REACT_APP_FREEPBX_URI), options);
                        if (session) {
                          session.connection.addEventListener('addstream', (s) => {
                              var audio = document.createElement('audio');
                              audio.srcObject = s.stream;
                              audio.play();
                              window.audio_v4 = audio;
                              window.steamchannel_4 = s.stream;
                              window.confie4 = true;
                          });
                        }
                        window.state4 = false;
                        window.confie4 = false;
                      }
                    }
                    if(window.steamchannel_5){
                      if(window.steamchannel_5.active == false && window.state5 == true && window.confie5 == true){
                        window.micval = 5;
                        console.log("this is 5 use to test the streem "+window.steamchannel_5.active);
                        var session = ua.call(window.calldata_5+(process.env.REACT_APP_FREEPBX_URI), options);
                        if (session) {
                          session.connection.addEventListener('addstream', (s) => {
                              var audio = document.createElement('audio');
                              audio.srcObject = s.stream;
                              audio.play();
                              window.audio_v5 = audio;
                              window.steamchannel_5 = s.stream;
                              window.confie5 = true;
                          });
                        }
                        window.state5 = false;
                        window.confie5 = false;
                      }
                    }
                    if(window.steamchannel_6){
                      if(window.steamchannel_6.active == false && window.state6 == true && window.confie6 == true){
                        window.micval = 6;
                        console.log("this is 6 use to test the streem "+window.steamchannel_6.active);
                        var session = ua.call(window.calldata_6+(process.env.REACT_APP_FREEPBX_URI), options);
                        if (session) {
                          session.connection.addEventListener('addstream', (s) => {
                              var audio = document.createElement('audio');
                              audio.srcObject = s.stream;
                              audio.play();
                              window.audio_v6 = audio;
                              window.steamchannel_6 = s.stream;
                              window.confie6 = true;
                          });
                        }
                        window.state6 = false;
                        window.confie6 = false;
                      }
                    }
                    if(window.steamchannel_7){
                      if(window.steamchannel_7.active == false && window.state7 == true && window.confie7 == true){
                        window.micval = 7;
                        console.log("this is 7 use to test the streem "+window.steamchannel_7.active);
                        var session = ua.call(window.calldata_7+(process.env.REACT_APP_FREEPBX_URI), options);
                        if (session) {
                          session.connection.addEventListener('addstream', (s) => {
                              var audio = document.createElement('audio');
                              audio.srcObject = s.stream;
                              audio.play();
                              window.audio_v7 = audio;
                              window.steamchannel_7 = s.stream;
                              window.confie7 = true;
                          });
                        }
                        window.state7 = false;
                        window.confie7 = false;
                      }
                    }
                  }, 6000);
                  // window.state = false;
                // }


              },
              'confirmed': function(e) {
                console.log('call confirmed');
                  if (window.mic1 != undefined && (window.mic1state == undefined || window.mic1state == true)) {
                    window.mic1.mute({ audio: true });
                  }
                  if (window.mic2 != undefined && (window.mic2state == undefined || window.mic2state == true)) {
                    window.mic2.mute({ audio: true });
                  }
                  if (window.mic3 != undefined && (window.mic3state == undefined || window.mic3state == true)) {
                    window.mic3.mute({ audio: true });
                  }
                  if (window.mic4 != undefined && (window.mic4state == undefined || window.mic4state == true)) {
                    window.mic4.mute({ audio: true });
                  }
                  if (window.mic5 != undefined && (window.mic5state == undefined || window.mic5state == true)) {
                    window.mic5.mute({ audio: true });
                  }
                  if (window.mic6 != undefined && (window.mic6state == undefined || window.mic6state == true)) {
                    window.mic6.mute({ audio: true });
                  }
                  if (window.mic7 != undefined && (window.mic7state == undefined || window.mic7state == true)) {
                    window.mic7.mute({ audio: true });
                  }
                

              },
              'addstream':  function(e) {
                console.log('>>> addstream');
              },
            };
            var options = {
              'eventHandlers'    : eventHandlers,
              mediaConstraints :
              {
                audio : true,
                video : false
              },
            };
            console.log("main page fully loadde");
            
            window.uaa = ua;
            window.call_option = options;
  
              
              window.onbeforeunload = function(){
              ua.unregister();
              ua.stop();
            };
          }
        return {
          ...state,
          username: action.username,
          sessiion_id: action.sessiion_id,
          authenticated: true,
          auth: false,
  
        };
      case SET_UNAUTHENTICATED:
        if(action.usr_ext_state == 1){
          let user_extension = localStorage.getItem("extension_num");
          if(user_extension){
            const body = {
              extension: user_extension,
                
              }
              let config = {
                  headers: {
                    'Content-Type': 'text/plain; charset=UTF-8',
                  }
              }
            axios.post('https://dmo1.myrianet.net/api/user-extension-status',body,config)
              .then((res) => {
                console.log(res.data);
              })
          }
          localStorage.removeItem('extension_num');

        }
        return {
          ...state,
          authenticated: false,
          loading: false,
          connecting: false,
          errors: {},
          credentials: {},
        };

      case SET_LOGOUTST:
              
              return {
                  ...state,
                  logoustated: true,
              };
  
      case SET_USER:
        return {
          ...state,
          authenticated: true,
          loading: false,
          ...action.payload,
        };
  
      case UPDATE_USER:
        return {
          ...state,
          loading: false,
          credentials: action.payload,
        };
  
      case CONNECTING:
        return {
          ...state,
          loading: true,
          connecting: true
        }
      case LOADING_USER:
        return {
          ...state,
          loading: true,
        };
      case LOGINFAILED:
        return {
          ...state,
          tabsession: action.tabsession,
          loading: false,
          connecting: false,
          errors: {
            type: 'WRONG USERNAME OR PASSWORD'
          }
        };
  
      default:
        return state;
    }
  };
  